import classNames from 'classnames'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './OfferCard.module.css'
import { OfferStickerProps } from './OfferCard.types'

export const OfferSticker = ({ sticker, isLarge }: OfferStickerProps) => {
  return (
    <div
      className={classNames(
        'rounded-full border-solid absolute grid place-content-center text-center p-2 bottom-4 right-4',
        styles.sticker,
        {
          'lg:bottom-12 lg:right-12 md:bottom-6 md:right-6': isLarge,
          'md:bottom-6 md:right-6': !isLarge, // Small Variant Styles
        }
      )}
      style={{
        borderColor: sticker?.borderColor || '#892B7D',
        color: sticker?.textColor || '#FFFFFF',
        backgroundColor: sticker?.backgroundColor || '#1E3B69',
      }}>
      <Typography
        tag={TypographyTag.p}
        variant={TypographyVariant.BodySmallBold}
        className={classNames(
          styles.stickerContent,
          '!font-bold text-greyscale-50'
        )}>
        {sticker.content}
      </Typography>
    </div>
  )
}
