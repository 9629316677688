import { Image } from '~/components/Image'
import { getImageSrc, getImageSrcSet } from '~/helpers'
import { OfferImageProps } from './OfferCard.types'

export const OfferImage = ({
  image,
  imagePosition = 'inline',
}: OfferImageProps) => {
  const wraperStyles =
    imagePosition === 'cover'
      ? 'absolute inset-0 h-full w-full'
      : 'absolute top-0 bottom-0 right-0 h-full max-w-full'
  const coverStyles =
    imagePosition === 'cover'
      ? 'object-cover h-full w-full'
      : 'object-contain h-full max-w-full'

  const mobileImg = image?.mobile || image?.tablet || image?.desktop
  const tabletImg = image?.tablet || image?.desktop || image?.mobile
  const desktopImg = image?.desktop || image?.tablet || image?.mobile

  return (
    <div className={wraperStyles}>
      <Image
        alt={desktopImg?.altText}
        src={getImageSrc(desktopImg?.src)}
        addSrcSet={false}
        width={desktopImg?.width}
        height={desktopImg?.height}
        className={coverStyles}
        sources={
          <>
            <source
              srcSet={getImageSrcSet(mobileImg?.src, [
                {
                  intrinsicImageSize: '700',
                },
              ])}
              media='(max-width: 768px)'
            />
            <source
              srcSet={getImageSrcSet(tabletImg?.src, [
                {
                  intrinsicImageSize: '500',
                },
              ])}
              media='(max-width: 1024px)'
            />
          </>
        }
      />
    </div>
  )
}
